body {
  margin: 0;
  font-family: "Horizons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Horizons';
  src: local("Horizons"), url("./fonts/Horizon.woff"),
       url('./fonts/Horizon.woff2');
  font-weight: normal;
  font-style: normal;
}
